import React, { useState, useRef } from 'react';
import Layout from '../../components/Layout'
import FarWadaadScript from '@hornofsilicon/far-wadaad'
import ReactAudioPlayer from 'react-audio-player';

const FarWadaad = () => {
  const startText = 'Xaggan hoose ku qor waxaad doontid';
  const fw = new FarWadaadScript({ withDiacritics: true, withDaal: true });
  const [farWadaad, setFarWadaad] = useState(fw.parse(startText));
  const [copy, setCopy] = useState('content_paste');

  const startUrl = `https://www.haadka.com/.netlify/functions/tts?text=${farWadaad}`
  const [url, setUrl] = useState(startUrl);

  const onKeyUpValue = (event) => {
    setCopy('content_paste');
    const fw = new FarWadaadScript({ withDiacritics: true, withDaal: true });
    const input = event.target.value;
    const text = fw.parse(input);
    setFarWadaad(text);
    setUrl(`https://www.haadka.com/.netlify/functions/tts?text=${text}`)
  }

  const onCopy = () => {
    navigator.clipboard.writeText(farWadaad).then(function () {
      setCopy('check')
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  }
  return (
    <Layout childDescription="Far Wadaad Baro">
      <div className="App">
      </div>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url('/img/berbera.png')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          maxHeight: '80px'
        }}
      >

      </div>

      <section className="section" style={{
        minHeight: '00px'
      }}>

        <div className="container">
          <div className="content">
            <h3 className="header" > Somali Text to Speech (by Far Wadaad) </h3>
            <div className="header-yellow arabic">
              <div className="controls-tss">
                <i onClick={onCopy} class="material-icons">{copy}</i>
              </div>
              <h1 dir="rtl">{farWadaad}</h1>
              <ReactAudioPlayer
                src={url}
                controls
                type="audio/mp3"
                preload="none"
              />
            </div>
            <div class="text-container">
              <textarea className="text" maxlength="500" placeholder={startText} onChange={onKeyUpValue.bind(this)}></textarea>
            </div>
          </div>
        </div>
      </section>
    </Layout >
  )
}

export default FarWadaad;